import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  actions: {
    flexGrow: 1,
  },
  button: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%',
  },
}));

const dialogHeaderStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(dialogHeaderStyles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const ContactButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#FC98E0'),
    borderRadius: 16,
    backgroundColor: '#FC98E0',
    '&:hover': {
      backgroundColor: '#fc90de',
    },
  },
}))(Button);

const CloseButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText('#DA5F8E'),
    borderRadius: 16,
    backgroundColor: '#DA5F8E',
    '&:hover': {
      backgroundColor: '#d45585',
    },
  },
}))(Button);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default ({ isOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpen);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenMap = () => {
    const url = 'https://www.chula.ac.th/academics/life-at-cu/cu-health-service-center/';
    window.open(url, '_blank', 'noreferrer');
  };

  return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}>
        </DialogTitle>
        <DialogContent style={{ textAlign: 'center' }}>
          {/* <ContentImage style={{ width: '100%', height: '100%' }} /> */}
          <img style={{ width: '100%', height: '100%' }}
            src={require(`../../../../assets/images/partner/chula/popup-content.svg`)} />
        </DialogContent>
        <DialogActions>
          <div className={classes.actions}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ContactButton
                  className={classes.button}
                  onClick={handleOpenMap}
                  color="primary">
                  ติดต่อศูนย์บริการสุขภาพแห่งจุฬาฯ เพื่อรักษาตามสิทธิ
                </ContactButton>
              </Grid>
              <Grid item xs={6}>
                <CloseButton
                  className={classes.button}
                  onClick={handleClose}
                  color="primary">
                  ชำระเงินด้วยตนเอง และปรึกษาแพทย์ทันที
                </CloseButton>
              </Grid>
            </Grid>
          </div>
        </DialogActions>
      </Dialog>
  );
}
