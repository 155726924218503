import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../../actions/appointment';
import { createAllCategoriesSelector } from '../../../reducers/appointment';
import { createLoadingSelector } from '../../../reducers/api';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Layout from '../../../components/v2/Layout';
import * as Constant from '../../../actions/constants';
import DynamicComponentLoader from '../../../components/DynamicComponentLoader';
import {
  getCorporateCardList,
  setCorporateId,
  checkCorporateComponentBlock,
  setAddOnBenefit,
  setConsultantListAvailableCondition
} from '../../../actions/partners';
import {
  checkMembershipCardBenefit,
  setCanUseBenefit,
} from '../../../actions/membership';
import {
  getPartnerId,
  getAddOnBenefit,
  getConsultantListAvailableCondition,
  getUrlParams
} from '../../../helpers/location';


const useStyles = makeStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  categoryImage: {
    width: '50%'
  },
  categoryName: {
    margin: 8,
    fontWeight: 'bold',
    textAlign: 'center',
    minHeight: 35,
    lineHeight: 1.3,
  },
  header: {
    paddingTop: 15,
    textAlign: 'center'
  }
}));

export default () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const categories = useSelector(createAllCategoriesSelector);
  const { componentBlock } = useSelector(state => state.partnerComponentBlock);
  const { canUseBenefit } = useSelector(state => state.memberships);
  const { user } = useSelector(state => state.session);
  const {
    cardId,
  } = useSelector(state => state.partners);
  const isLoading = useSelector(createLoadingSelector([
    'appointment/categories/get',
    Constant.PARTNERS_COMPONENT_BLOCK_GET_REQUEST,
    Constant.MEMBERSHIP_CARD_BENEFIT_CHECK_GET_REQUEST
  ]));

  React.useEffect(() => {
    if (user) {
      storeAddOnBenefit();
      storeAvailableCondition();
      fetchData();
    }

    if (cardId)
      checkCanUseAddOnBenefit()
        .then(canUse => {
          if (canUse === undefined) return Promise.resolve(undefined);
          if (canUse) return Promise.resolve(false);
          return showCannotUseBenefitPage();
        })
        .then(showCannotUseBenefitPage => {
          const waitingForResponse = showCannotUseBenefitPage === undefined;
          if (showCannotUseBenefitPage || waitingForResponse) return Promise.resolve(false);
          return checkSkipCategories();
        })

  }, [user, cardId, canUseBenefit]);

  const storeAddOnBenefit = () => {
    const addOnBenefit = getAddOnBenefit(window.location.search);
    dispatch(setAddOnBenefit(addOnBenefit));
  }

  const storeAvailableCondition = () => {
    const isAvailableOnly = getConsultantListAvailableCondition(window.location.search);
    if (!isAvailableOnly) return
    dispatch(setConsultantListAvailableCondition(isAvailableOnly));
  }

  const fetchData = async () => {
    const partnerId = getPartnerId(window.location.search);
    dispatch(setCorporateId(partnerId));

    await dispatch(getCorporateCardList(partnerId));
    await dispatch(getCategories(null, false, true));
    const currentPath = window.location.pathname;
    if (partnerId) dispatch(checkCorporateComponentBlock(partnerId, currentPath));
  }

  const checkCanUseAddOnBenefit = () => {
    return new  Promise((resolve, reject) => {
      const addOnBenefit = getAddOnBenefit(window.location.search);
      const params = getUrlParams(window.location.search);
      const passVerify = params.passVerify;

      if (!addOnBenefit || passVerify) resolve(true);

      dispatch(checkMembershipCardBenefit(cardId, true, addOnBenefit))

      const result = canUseBenefit !== null ? canUseBenefit : undefined;
      resolve(result);
    });
  }

  const showCannotUseBenefitPage = () => {
    return new Promise((resolve, reject) => {
      if (canUseBenefit) resolve(false);

      dispatch(setCanUseBenefit(null));
      dispatch(setAddOnBenefit(null));
      dispatch(setConsultantListAvailableCondition(undefined));

      const params = getUrlParams(window.location.search);
      
      const passVerify = params.passVerify;
      if (passVerify) return resolve(false);

      const { add_on_benefit, is_available_only, ...excludeAddOnBenefitParams } = params;
      const queryString = new URLSearchParams(excludeAddOnBenefitParams).toString();
      const pathname = window.location.pathname;
      const pathWithParams = `${pathname}&${queryString}`
      navigate(`/membership/cannotUseBenefit?flow=consult&nextPage=${pathWithParams}`);

      reject()
    });
  }

  const isSkip = (() => {
    if (typeof window === 'undefined' ) return false;
    
    const params = getUrlParams(window.location.search);
    const skip = params.skip_categories;
    return !!skip
  });

  const checkSkipCategories = () => {
    return new Promise((resolve, reject) => {
      if (isSkip() && categories.length !== 0) {
        onClick(categories[0])
        resolve();
      } else{
        resolve(false);
      }
    });
  };

  const onClick = (category) => {
    window.logEvent('select_content', { content_type: 'consult_category', item_id: category.id + '', item_name: category.name });
    navigate(`/partners/consult/chooseConsultant?categoryId=${category.id}`);
  }

  return (
    <div>
      <Layout bg="bg1" title="ต้องการพบแพทย์ด้านใด" loading={isLoading && !categories.length || isSkip()}>
        <Grid container spacing={2}>
          {categories.map(category => (
            <Grid item xs={6} sm={6} md={3} key={category.id}>
              <Card className={classes.card} onClick={() => onClick(category)}>
                <CardMedia component='img' image={category.image} className={classes.categoryImage} />
                <Typography variant="subtitle1" component="p" color="textPrimary" className={classes.categoryName}>
                  {category.name}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Layout>
      { componentBlock && !isSkip() && 
        <DynamicComponentLoader
          componentName={componentBlock}
          loadedComponentProps={{ isOpen: true }} />
      }
    </div>
  );
};
