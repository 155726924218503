import * as Constant from './constants';
import * as Api from '../apis';

export const postMembershipVerify = (payload) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.PARTNER_VERIFY_POST_REQUEST });
    const { partners } = getState();
    return Api.postMembershipVerify(payload, partners.partnerId)
      .then(res => {
        dispatch({
          type: Constant.PARTNER_VERIFY_POST_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(message => {
        dispatch({ type: Constant.PARTNER_VERIFY_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const employeeHealthRegister = (payload) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.EMPLOYEEHEALTH_REGISTER_REQUEST });
    const { partners } = getState();
    return Api.employeeHealthRegister(payload, partners.partnerId)
      .then(res => {
        dispatch({
          type: Constant.EMPLOYEEHEALTH_REGISTER_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(message => {
        dispatch({ type: Constant.EMPLOYEEHEALTH_REGISTER_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const getCorporateCardList = (partnerId) => {
  return dispatch => {
    dispatch({ type: Constant.PARTNERS_CARDS_GET_REQUEST });
    return Api.getCorporateCardList(partnerId)
      .then(payload => {
        dispatch({ type: Constant.PARTNERS_CARDS_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.PARTNERS_CARDS_GET_FAILED, message });
      });
  };
};

export const checkCorporateComponentBlock = (partnerId, page) => {
  return dispatch => {
    dispatch({ type: Constant.PARTNERS_COMPONENT_BLOCK_GET_REQUEST });
    const data = {
      'page': page
    }
    return Api.checkCorporateComponentBlock(partnerId, data)
      .then(payload => {
        dispatch({ type: Constant.PARTNERS_COMPONENT_BLOCK_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        const errorMessage = message.message.detail;
        if (/not\s*found/i.test(errorMessage.toLowerCase())) {
          dispatch({ type: Constant.PARTNERS_COMPONENT_BLOCK_GET_NOTFOUND, message });
        } else {
          dispatch({ type: Constant.PARTNERS_COMPONENT_BLOCK_GET_FAILED, message });
        }
      });
  };
};

export const setCorporateId = (id) => ({ type: Constant.SET_CORPORATE_ID, payload: id })

export const setAddOnBenefit = (addOnBenefit) => ({ type: Constant.SET_ADD_ON_BENEFIT, payload: addOnBenefit });

export const setConsultantListAvailableCondition = (isAvailableOnly) =>
  ({ type: Constant.SET_CONSULTANT_LIST_AVAILABLE_CONDITION, payload: isAvailableOnly });

export const postSummaryAppointmentPartners = (partnerId, cardId, addOnBenefit) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.PARTNER_APPOINTMENT_SUMMARY_POST_REQUEST });
    const { orderInfo } = getState();
    const { consultantId, promotionId, consultNow, slotTimeId } = orderInfo;
    const val = {
      consultant_id: consultantId,
      promotion_id: promotionId,
      add_on_benefit: addOnBenefit
    };
    if (!consultNow) {
      val.time_slot_id = slotTimeId;
    }
    return Api.postSummaryAppointment(val, partnerId, cardId, !!partnerId)
      .then(res => {
        dispatch({
          type: Constant.PARTNER_APPOINTMENT_SUMMARY_POST_SUCCESS,
          payload: res,
        });
        return res;
      })
      .catch(message => {
        dispatch({ type: Constant.PARTNER_APPOINTMENT_SUMMARY_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};