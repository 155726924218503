import React from 'react';
import ChulaDirectionDialog from '../PartnerPopup/Chula/DirectionDialog';

const components = {
  ChulaDirectionDialog,
};

export default function DynamicComponentLoader({ componentName, loadedComponentProps }) {
  const Component = components[componentName] || null;

  return (
    <div>
      {Component 
        ? <Component {...loadedComponentProps} />
        : <div> not found</div>}
    </div>
  );
}
